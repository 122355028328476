import React from 'react';
// import logo from './logo.svg';
import './App.css';
import NavBar from './Components/NavBar.tsx';
import LandingPage from './Components/LandingPage.tsx';
import InfoPage from './Components/InfoPage.tsx';
import LandingText from './Components/FAQ.tsx';
import Footer from './Components/Footer.tsx';
import VideoComponent from 'Components/Video.tsx';


function App() {
  return (
    <div className="App"style={{overflow:'hidden',background:'#FFFCF2'}}>
     <>
     <NavBar/>
     <LandingText/>
     <LandingPage/>
     <InfoPage/>
    {/* <VideoComponent/> */}
     <Footer/>
     </>
    </div>
  );
}

export default App;
