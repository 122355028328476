import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete'
import { ReactComponent as Fi_Upload } from './images/Fi_Upload.svg';
import { ReactComponent as YellowCheckMark } from './images/YellowCheckMark.svg';
import IconButton from '@mui/material/IconButton';


const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '85%', sm: '75%', md: '55%' },
  maxWidth: 700,
  minWidth: 320,
  height: 'auto',
  maxHeight: '90vh',
  bgcolor: 'background.paper',
  borderRadius: '12px',
  boxShadow: 24,
  p: { xs: 2, sm: 4 },
  display: 'flex',
  flexDirection: 'column' as 'column',
  alignItems: 'left',
  justifyContent: 'flex-start',
};

const loadingStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  height: '100%', // Center loading indicator vertically
};

const submitButtonStyle = {
  backgroundColor: 'black',
  color: 'white',
  borderRadius: '25px',
  padding: '10px 20px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#333',
  },
  width: 'fit-content',
};
const CancelButtonStyle = {
  backgroundColor: 'White',
  color: 'black',
  borderRadius: '25px',
  padding: '10px 20px',
  textTransform: 'none',
  ml:2,
  border:'1px solid black',
  '&:hover': {
    backgroundColor: '#333',
  },
  width: 'fit-content',
};

const dropzoneStyle = {
  display: 'flex',
  flexDirection: 'column' as 'column',
  alignItems: 'center',
  justifyContent: 'center',
  border: '2px dashed #ccc',
  borderRadius: '8px',
  padding: '15px',
  cursor: 'pointer',
  marginTop: '2px',
};

interface CareersModalProps {
  open: boolean;
  onClose: () => void;
  isContactUs?: boolean;
}

const CareersModal: React.FC<CareersModalProps> = ({ open, onClose, isContactUs }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [file, setFile] = useState<any>(null);
  const [fileName, setFileName] = useState<string>('');
  const [responseMessage, setResponseMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<any>('');
  const [touched, setTouched] = useState({ name: false, email: false, file: false });
  const [isFormValid, setIsFormValid] = useState(false);

  const validateEmail = (value: string) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(value);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);

    if (touched.email && !validateEmail(value)) {
      setError((prev:any) => ({ ...prev, email: 'Please enter a valid email address.' }));
    } else {
      setError((prev:any) => ({ ...prev, email: '' }));
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setName(value);

    if (touched.name && value.trim() === '') {
      setError((prev:any) => ({ ...prev, name: 'Name is required.' }));
    } else {
      setError((prev:any) => ({ ...prev, name: '' }));
    }
  };

  const handleBlur = (field: keyof typeof error) => {
    setTouched((prev) => ({ ...prev, [field]: true }));
  };

  const onDrop = (acceptedFiles: File[]) => {
    const selectedFile = acceptedFiles[0];
    setFile(selectedFile);
    setFileName(selectedFile.name);

    setError((prev:any) => ({ ...prev, file: '' }));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'application/msword': ['.doc'],
    },
  });
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
 
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result as string; // Assert that reader.result is a string
        if (result) {
          const base64String = result.split(',')[1]; // Get Base64 part
          setFile({ name: file.name, data: base64String });
        }
      };
      reader.readAsDataURL(file);
    }
  };
  console.log(file?.name)
console.log(file?.data)
  const onRemove = () => {
    setFile(null);
    setFileName('');
    if (touched.file) {
      setError((prev:any) => ({ ...prev, file: 'Resume is required.' }));
    }
  };
  useEffect(() => {
    const isNameValid = name.trim() !== '';
    const isEmailValid = validateEmail(email);
    const isFileValid = file !== null;

    setIsFormValid(isNameValid && isEmailValid && isFileValid);

    if (touched.name && !isNameValid) {
      setError((prev:any) => ({ ...prev, name: 'Name is required.' }));
    }

    if (touched.email && !isEmailValid) {
      setError((prev:any) => ({ ...prev, email: 'Please enter a valid email address.' }));
    }

    if (touched.file && !isFileValid) {
      setError((prev:any) => ({ ...prev, file: 'Resume is required.' }));
    }
  }, [name, email, file, touched]);



  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setTouched({ name: true, email: true, file: true })   
    if (!isFormValid) return;
    setLoading(true);
    setResponseMessage('');
    const formData = {
      formType: 'fullForm',
      name,
      email,
      phone,
      message,
      file: file ? file.data : null,
    };
    try {
      const response = await fetch('https://script.google.com/macros/s/AKfycbx__6ipoZg9Z2jWJ4HwSwhHOzUZI4Vfx9fqa33u_B2jYdMuPqDnMatryAIiym-k3qoEgA/exec', {
        method: 'POST',
        body: JSON.stringify(formData),
      });

      const result = await response.text();
      setResponseMessage(result);
      setShowBanner(true);
      setSuccess(true);

      setTimeout(() => setShowBanner(false), 10000);

      // Clear form fields
      setName('');
      setEmail('');
      setPhone('');
      setMessage('');
      setFile(null);
      setFileName('');
      setTouched({ name: false, email: false, file: false });
    } catch (error) {
      console.error('Error:', error);
      setResponseMessage('An error occurred while submitting the form.');
      setShowBanner(true);
      setSuccess(false);

      setTimeout(() => setShowBanner(false), 10000);
    } finally {
      setLoading(false); 
    }
  };

  // Reset fields when the modal is opened
  useEffect(() => {
    if (open) {
      setName('');
      setEmail('');
      setPhone('');
      setMessage('');
      setFile(null);
      setFileName('');
      setResponseMessage('');
      setShowBanner(false);
      setSuccess(false);
    }
  }, [open]);

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="careers-modal-title" aria-describedby="careers-modal-description">
      <Box sx={modalStyle}>
        {success ? (
          <Box sx={{ textAlign: 'center' }}>
            <YellowCheckMark />
            <Typography variant="h6" sx={{ mb: 2 }}>Thank you for applying!</Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>We will get back to you shortly.</Typography>
            <Button sx={submitButtonStyle} onClick={onClose}>
              Home
            </Button>
          </Box>
        ) : (
          <>
            {!loading && (
              <Typography variant="h5" component="h1" sx={{ mb: 2 }}>
                Work With Us
              </Typography>
            )}

            {showBanner && (
              <Alert severity={responseMessage.includes('error') ? 'error' : 'success'} sx={{ mb: 2 }}>
                {responseMessage}
              </Alert>
            )}

            {loading ? (
              <Box sx={loadingStyle}>
                <CircularProgress sx={{ color: 'black' }} />
              </Box>
            ) : (
              <form onSubmit={handleSubmit}>
                <Typography variant="body1" gutterBottom>
                  Name <span style={{ color: 'red' }}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Enter your name"
                  value={name}
                  onChange={handleNameChange}
                  onBlur={() => handleBlur('name')}
                  error={!!error.name}
                  helperText={error.name || ''}
                  sx={{ mb: 1 }}
                />

                <Box display="flex" gap={2} sx={{ mb: 1 }}>
                  <Box flex={1}>
                    <Typography variant="body1" gutterBottom>
                      Email <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Enter your email"
                      value={email}
                      onChange={handleEmailChange}
                      onBlur={() => handleBlur('email')}
                      error={!!error.email}
                      helperText={error.email || ''}
                    />
                  </Box>
                  <Box flex={1}>
                    <Typography variant="body1" gutterBottom>
                      Phone
                    </Typography>
                    <TextField
                      fullWidth
                      variant="outlined"
                      placeholder="Enter your phone number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value.replace(/\D/g, '').slice(0, 10))}
                    />
                  </Box>
                </Box>

               
                  <>
                    <Typography variant="body1" sx={{ mt: 1 }} gutterBottom>
                      Upload Resume <span style={{ color: 'red' }}>*</span>
                    </Typography>
                    <Box {...getRootProps()} sx={dropzoneStyle} >
                      {/* onClick={() => setTouched((prev) => ({ ...prev, file: true }))}  // onclick for upload */}
                      {/* <input {...getInputProps()}/>
                      <Fi_Upload width="48" height="38"  /> */}
                      <label htmlFor="file-upload">
                <input
                  id="file-upload"
                  type="file"
                  accept=".pdf, .docx"
                  style={{ display: 'none' }}
                  onChange={handleFileChange}
                />
                <IconButton component="span" sx={{ marginBottom: '10px' }}>
                  <Fi_Upload />
                </IconButton>
              </label>
                      <Typography variant="body2" color="textSecondary">
                        Drag & drop your file here, or click to choose (PDF, DOCX,WORD)
                      </Typography>
                      {file && (
                        <Box display="flex" alignItems="center" justifyContent="center" sx={{ width: '100%', mt: 1 }}>
                          <Typography variant="body2">{file.name}</Typography>
                          <IconButton onClick={onRemove} size="small" color="error">
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      )}
                    </Box>
                    {error.file && <Typography color="error" variant="body2" sx={{ mt: 1 }}>{error.file}</Typography>}
                  </>

                <Typography variant="body1" sx={{ mt: 1 }} gutterBottom>
                  Message
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Enter your message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  multiline
                  rows={4}
                  sx={{ mb: 2 }}
                />
              <Box sx={{display:'flex',flexDirection:'row',justifyContent:'flex-start'}}>
                <Button sx={{backgroundColor:!isFormValid? 'grey':'black',
                              color:'white',
                              borderRadius: '25px',
                              padding: '10px 20px',
                              textTransform: 'none',
                              '&:hover': {
                                backgroundColor: '#333',},
  width: 'fit-content',}} type="submit" disabled={!isFormValid || loading}>
                  {loading ? <CircularProgress size={24} /> : 'Submit'}
                </Button>
                <Button sx={CancelButtonStyle} onClick={onClose} >
                  Cancel
                </Button>
              </Box>
              </form>
            )}
          </>
        )}
      </Box>
    </Modal>
  );
};

export default CareersModal;