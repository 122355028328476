import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { ReactComponent as Logo } from './images/logo.svg';
import CareersModal from "./CareersModal.tsx";

const Navbar: React.FC = () => {
    const [careersModalOpen, setCareersModalOpen] = useState(false);
    const [careersbutton, setcareersbutton] = useState(false);
    const handleWorkWithUsOpen = () => {
        setCareersModalOpen(true);
        setcareersbutton(true);
    };

    const handleWorkWithUsClose = () => {
        setCareersModalOpen(false);
    };

    return (
        <Box sx={{ flexGrow: 1,overflowX:'hidden',width:'100%'}}>
            <AppBar position="static" sx={{ background: 'transparent', boxShadow: 'none', border: 'none', marginTop: 0 }}>
                <Container maxWidth="lg">
                    <Toolbar disableGutters>
                        <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                            <a href='/'><Logo style={{ width: 140, height: 70 }} /></a>
                        </Box>
                        <Box sx={{ flexGrow: 1 }} />
                        <Box sx={{ display: "flex", gap: 2 }}>
                            <Button 
                                sx={{
                                    color: 'white',
                                    backgroundColor: careersbutton?'black':'#B58024',
                                    padding: '8px 24px', // Adjusted padding for rounded shape
                                    borderRadius: '0 0 20px 20px ', // Top rounded corners only
                                    margin: 0,
                                    '&:hover': {
                                        backgroundColor: 'black' // Darker background on hover
                                    },
                                    height: '30px',
                                    width: { xs: '40%', sm: '30%', md: '20%', lg: '15%' },
                                    fontSize:{xs:'7px',sm:'10px',md:'12px',lg:'12px',xl:'12px'},
                                    position:'absolute',
                                    top:'5%',
                                    right:'0%'
                                    
                                }} 
                                onClick={handleWorkWithUsOpen}
                            >
                                Work With Us
                            </Button>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
            <CareersModal 
                open={careersModalOpen} 
                onClose={handleWorkWithUsClose} 
                isContactUs={false}
            />
        </Box>
    );        
};

export default Navbar;
