import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as CurvedLine } from './images/CurvedLine.svg';
import { ReactComponent as Connection } from './images/Connection.svg';
import { ReactComponent as ArrowMark } from './images/ArrowMark.svg';
import { ReactComponent as DropMail } from './images/DropMail.svg';
import { ReactComponent as CheckMark } from './images/CheckMark.svg';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import CircularProgress from '@mui/material/CircularProgress';

const LandingPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // State to manage email input and submission status
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [showForm, setShowForm] = useState(true); 
  const [countdown, setCountdown] = useState(10); 
  const [timerActive, setTimerActive] = useState(false);
const validateEmail = (value:any) =>{
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
 return emailPattern.test(value)
}
  const handleEmailChange = (e:any) =>{
  const value  = e.target.value
  setEmail(value);
  if(value === ''){
    setError('')
  }else if(!validateEmail(value)){
      setError('Please enter a valid Email address.')
    } else{
      setError('')
    }
  }

  const handleSubmit = async (e:any) => {
    if(validateEmail(email)){
    e.preventDefault();
    setLoading(true);
    setSuccessMessage('');
    setCountdown(10); 
    setTimerActive(false); 

    const scriptURL = 'https://script.google.com/macros/s/AKfycbx__6ipoZg9Z2jWJ4HwSwhHOzUZI4Vfx9fqa33u_B2jYdMuPqDnMatryAIiym-k3qoEgA/exec'
    try {
      await fetch(scriptURL, { method: 'POST', body: JSON.stringify({ formType: 'emailOnly',email }) });
      setSuccessMessage('Thank you for your Interest!');
      setEmail('');
      setShowForm(false);
      setTimerActive(true);
      const interval = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(interval);
            return 0; 
          }
          return prev - 1;
        });
      }, 1000);
      setTimeout(() => {
        setShowForm(true); 
        setTimerActive(false);
      }, 10000);
    } catch (error) {
      console.error('Error sending email:', error);
      setShowForm(true); // Show form again in case of error
    } finally {
      setLoading(false);
    }
  }
  else{
    setError('Please enter a valid email address')
  }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          flex:1,
          // minHeight: { xs: '100%', sm: '70vh', md: '60vh', lg: '100vh' },
          position: 'relative',
          width: '100%',
          // height:'100%',
          mt:3,
        }}
      >
        <Box
          component={Connection}
          sx={{
            width: { xs: '90%', md: '100%',lg:'80%' },
            height: '100vh',
            zIndex: 0,
            opacity: 1,
            aspectRatio: '16/9',
          }}
        >
          </Box>

        <Box
          sx={{
            position: 'absolute',
            top: { xs: '56%', sm: '62%', md: '64%', lg: '74%',xl:'82%'},
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '50px',
            height: '50px',
            borderRadius: '50%',
            backgroundColor: 'black',
            
          }}
        >
          <KeyboardDoubleArrowDownIcon
            style={{
              width: '30px',
              height: '30px',
              color: 'white',
              animation: 'moveIcon 1s ease-in-out infinite',
              margin: '10px auto',
            }}
          />
        </Box>

        <Box
          sx={{
            position: 'absolute',
            width: { xs: '30%', sm: '20%', md: '20%' ,lg:'20%',xl:'20%'},
            height: { xs: '20%', sm: '20%', md: '20%',lg:'20%',xl:'20%'},
            top: { xs: '-5%', sm: '-1%', md: '2%',lg:'-1%',xl:'-2%' },
            right:{ xs: '26%', sm: '15%', md: '20%',lg:'20%',xl:'20%' },
            zIndex: 2,
            marginTop: '-50px',
            aspectRatio: '4/1',
          }}
        >
          <ArrowMark style={{ width: "100%", height: '100%' }} />
        </Box>

        <Card
  sx={{
    position: 'absolute',
    width: { xs: '60%', sm: '60%', md: '60%', lg: '60%' },
    maxWidth: '750px',
    top: '24%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: 3,
    zIndex: 1,
    textAlign: 'center',
    backgroundColor: '#F5C10E',
    borderRadius: '16px',
    boxShadow: 3,
    maxHeight: { xs: '45vh', sm: '60vh', md: '50vh' }, // Fixed max height based on screen size
    overflowY: 'auto', // Adds scroll if content exceeds maxHeight
  }}
>
          <Typography variant="h6" sx={{ fontWeight: 600, ml: 2 }}>
            Join Our Waitlist
          </Typography>

          <Box sx={{ width: '100%', background: 'white', marginTop: '30px', borderRadius: '16px', padding: '0.5%' }}>
            <Typography sx={{ textAlign: 'center', fontSize: '23px', fontFamily: 'Aleo' }}>
              Join our Community and get access to <b>Financial Structure,</b>
            </Typography>
            <Typography sx={{ textAlign: 'center', fontSize: '23px', fontFamily: 'Aleo' }}>
              Clients and <b>A Community</b> of like-minded hylacers.
            </Typography>
            <Typography sx={{ textAlign: 'center', paddingTop: '3%', fontSize: '17px', fontFamily: 'Plus Jakarta Sans' }}>
              Sit Tight! Give us some time to go through your profile for a smooth onboarding process.
            </Typography>

            {showForm ? (
              <form onSubmit={handleSubmit}>
                <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row',alignItems:isMobile?'center':'baseline', gap: 2, mt: '5%',mb:'2%' }}>
                  <Typography sx={{ padding: '2%', fontWeight: 600 }}>Drop an Email to us</Typography>
                  <TextField
                    placeholder="Enter your Email Address"
                    variant="outlined"
                    value={email}

                    onChange={handleEmailChange}
                    required
                    sx={{ width: '100%', maxWidth: '380px', "& .MuiOutlinedInput-root": { borderRadius: '25px' } }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <DropMail />
                        </InputAdornment>
                      ),
                    }}
                  />
                  
                  <button
                    type="submit"
                    style={{
                      borderRadius: '20px',
                      background: 'black',
                      color: 'white',
                      // padding: '12px 0',
                      // boxSizing:'border-box',
                      width: '100%',
                      maxWidth: '120px',
                      height:'8vh',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '8px',
                    }}
                    disabled={loading}
                  >
                    {loading ? (
                      <>
                        <CircularProgress size={20} color="inherit" />
                        <span style={{ marginLeft: '8px' }}>Loading...</span>
                      </>
                    ) : (
                      'Submit'
                    )}
                  </button>
                  
                </Box>
                {error && <Typography style={{color:'red',marginRight:'12%'}}>{error}</Typography>}
                
              </form>
            ) : (
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
                <CheckMark />
                <Typography sx={{ fontSize: '20px', fontWeight: 600 }}>{successMessage}</Typography>
                {timerActive && (
                  <Typography sx={{ marginTop: 2, fontSize: '16px', fontWeight: 500 }}>
                    Thank you for your interest! Redirecting back to home in {countdown} seconds...
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        </Card>
      </Box>
      {/* </Box> */}
       <style>
        {`
          @keyframes moveIcon {
            0% { transform: translateY(0); }
            50% { transform: translateY(-10px); }
            100% { transform: translateY(0); }
          }
        `}
      </style>
    </>
  );
};

export default LandingPage;
