import React from 'react';
import Typography from '@mui/material/Typography';
import { ReactComponent as CurvedLine } from './images/CurvedLine.svg';
import Box from '@mui/material/Box';

const LandingText: React.FC = () => {
  return (
<Box sx={{  display: 'flex', justifyContent: 'center',mb:5 }}>
        <Typography
          component="h2"
          sx={{
            fontSize: '45px',
            fontWeight: 600,
            width: { xs: 300, sm: 550, md: 700 },
            textAlign: 'center',
            lineHeight: { xs: 1.2, sm: 1 },
          }}
        >
          Build your Network & Networth
          <Box component="span" sx={{ position: 'relative', display: 'inline-block' }}>
            with
            <Box
              component={CurvedLine}
              sx={{
                transform: 'translateY(30%)',
                width: 'auto',
                height: 'auto',
              }}
            />
          </Box>
          Community
        </Typography>
      </Box>
  );
};

export default LandingText;
