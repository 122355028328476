import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Box from '@mui/material/Box';
import { ReactComponent as DottedMap } from './images/DottedMap.svg';
import { ReactComponent as ClientFirst } from './images/ClientFirst.svg';
import { ReactComponent as ClientTwo } from './images/ClientTwo.svg';
import { ReactComponent as HylancerFirst } from './images/HylancerFirst.svg';
import { ReactComponent as HylancerTwo } from './images/HylancerTwo.svg';
import { ReactComponent as RopeOne } from './images/RopeOne.svg';
import { ReactComponent as RopeTwo } from './images/RopeTwo.svg';
import { Card, CardContent, Typography } from '@mui/material';

const IconGrid: React.FC = () => {
  const [animationState, setAnimationState] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimationState((prev) => (prev + 1) % 10);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // height: '100vh', // Full viewport height
        padding: '0 16px', // Side padding for smaller screens
      }}
    >
      {/* Wrapper for images with DottedMap centered */}
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          maxWidth: '1200px', // Limit max width for larger screens
          aspectRatio: '4 / 3',
        }}
      >
        <DottedMap style={{ width: '100%', height: 'auto' }} />

        {/* ClientFirst */}
        {(animationState === 0 || animationState === 1 || animationState === 2 || animationState === 3) && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            style={{
              position: 'absolute',
              top: '8%',
              left: '10%',
              aspectRatio: '1',
              width: '25%',
            }}
          >
            <ClientFirst style={{ width: '100%', height: 'auto' }} />
          </motion.div>
        )}

        {/* RopeOne */}
        {(animationState === 1 || animationState === 2 || animationState === 3) && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            style={{
              position: 'absolute',
              top: '17%',
              left: '37%',
              aspectRatio: '3 / 1',
              width: '35%',
            }}
          >
            <RopeOne style={{ width: '100%', height: 'auto', transform: 'rotate(-10deg)' }} />
          </motion.div>
        )}

        {/* HylancerTwo */}
        {(animationState === 2 || animationState === 3) && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            style={{
              position: 'absolute',
              bottom: '15%',
              right: '2%',
              aspectRatio: '1',
              width: '25%',
            }}
          >
            <HylancerFirst style={{ width: '100%', height: 'auto' }} />
          </motion.div>
        )}

        {/* Card1 */}
        {animationState === 3 && (
          <motion.div
            initial={{ scale: 1 }}
            animate={{ scale: 1.1 }}
            transition={{ duration: 0.5 }}
            style={{
              position: 'absolute',
              top: '30%',
              left: '41%',
              transform: 'translate(-50%, -50%)',
              width: '30%',
              aspectRatio: '3 / 2',
            }}
          >
            <Card
              sx={{
                width: '100%',
                height: {xs:'100%',sm:'100%',md:'100%',lg:'50%'},
                opacity: 0.8,
                background: '#F7F0DB',
                border: '1px solid #B58024',
                borderRadius: '20px',
              }}
            >
              <CardContent>
                <Typography gutterBottom variant="h5" component="div" sx={{ textAlign: 'center',fontSize: { xs: '2vw', sm: '2.5vw', md: '1.5vw' }, fontWeight: 600, color: 'black' }}>
                  Connecting
                </Typography>
                <Typography variant="body2" sx={{ textAlign: 'center',fontSize: { xs: '2vw', sm: '2vw', md: '1vw' }, color: 'black' }}>
                  Business owners to Hylancers
                </Typography>
              </CardContent>
            </Card>
          </motion.div>
        )}

        {/* ClientTwo */}
        {(animationState === 5 || animationState === 6 || animationState === 7 || animationState === 8) && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            style={{
              position: 'absolute',
              bottom: '10%',
              left: '15%',
              aspectRatio: '1',
              width: '25%',
            }}
          >
            <ClientTwo style={{ width: '100%', height: 'auto' }} />
          </motion.div>
        )}

        {/* RopeTwo */}
        {(animationState === 6 || animationState === 7 || animationState === 8) && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            style={{
              position: 'absolute',
              bottom: '25%',
              left: '36%',
              aspectRatio: '3 / 1',
              width: '37%',
            }}
          >
            <RopeTwo style={{ width: '100%', height: 'auto', transform: 'rotate(90deg)' }} />
          </motion.div>
        )}

        {/* HylancerFirst */}
        {(animationState === 7 || animationState === 8) && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            style={{
              position: 'absolute',
              top: '10%',
              right: '10%',
              aspectRatio: '1',
              width: '25%',
            }}
          >
            <HylancerTwo style={{ width: '100%', height: 'auto' }} />
          </motion.div>
        )}

        {/* Card2 */}
        {animationState === 8 && (
          <motion.div
            initial={{ scale: 1 }}
            animate={{ scale: 1.1 }}
            transition={{ duration: 0.5 }}
            style={{
              position: 'absolute',
              bottom: '35%',
              left: '37%',
              transform: 'translate(-50%, -50%)',
              width: '30%',
              aspectRatio: '3 / 2',
            }}
          >
            <Card
              sx={{
                width: '100%',
                height: {xs:'100%',sm:'100%',md:'100%',lg:'50%'},
                opacity: 0.8,
                background: '#F7F0DB',
                border: '1px solid #B58024',
                borderRadius: '20px',
              }}
            >
              <CardContent>
                <Typography gutterBottom variant="h5" component="div" sx={{ textAlign: 'center',fontSize: { xs: '2vw', sm: '2.5vw', md: '1.5vw' }, fontWeight: 600, color: 'black' }}>
                  Connecting
                </Typography>
                <Typography variant="body2" sx={{ textAlign: 'center',fontSize: { xs: '2vw', sm: '2vw', md: '1vw' }, color: 'black' }}>
                  Enterprises to Hylancers
                </Typography>
              </CardContent>
            </Card>
          </motion.div>
        )}
      </Box>
    </Box>
  );
};

export default IconGrid;
